<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import userService from '../../../helpers/userService';

/**
 * Starter component
 */
export default {
  page: {
    title: "My Syllabus",
  },
  head() {
    return {
      title: `student-syllabus`,
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  async created() {
    await this.$store.dispatch("user/mySyllabus",userService.methods.userYear());
    this.baseUrl = process.env.VUE_APP_BASE_URL;
  },
  data: () => 
  ({    
    baseUrl: null,
    title: "My Syllabus",
    items: [
      {
        text: "Manage",
        href: "/home"
      },
      {
        text: "Attendance",
        href: "/myattendance"
      }
    ],
  }),
  computed:{
    syllabus(){
      return this.$store.getters['user/mySyllabus']
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
              <ul class="sitemap">
                  <li>
                    <a
                      href="javascript: void(0);"
                      class="text-uppercase font-weight-bold"
                    >
                      <img
                        src="@/assets/images/logo-sm-dark.png"
                        alt
                        height="12"
                        class="mr-1"
                      />
                      My Syllabus
                    </a>
                    <ul v-for="chapter in syllabus" :key="chapter">
                      <li>
                        <a href="javascript: void(0);" class="text-dark">
                          <i class="ri-book-line mr-1"></i>{{ chapter.number + ' - ' + chapter.name }}
                        </a>
                        <ul v-for="lesson in chapter.lesson" :key="lesson">
                          <li>
                            <a href="javascript: void(0);" class="text-secondary">
                              <i class="ri-ruler-line mr-1"></i>{{ lesson.number + ' - ' + lesson.name }}
                            </a>
                            <ul v-for="video in lesson.video" :key="video">
                              <li>
                                <router-link :to="(!video.lock)?`/videos/my-sessions/${video.id}`:'#'" class="text-warning">
                                  <i class="ri-video-fill mr-1"></i>{{ video.attachment }}
                                  <i class="mdi mdi-lock text-danger" v-if="video.lock"></i>
                                  <i class="mdi mdi-lock-open-variant text-success" v-else></i>
                                </router-link>
                              </li>
                            </ul>
                            <ul v-for="material in lesson.material" :key="material">
                              <li>
                                <a :href="(!material.lock)?`${baseUrl}material/download?id=${material.id}`:void(0)" target="_blank" class="text-purple">
                                  <i class="ri-file-line mr-1"></i>{{ material.attachment }}
                                  <i class="mdi mdi-lock text-danger" v-if="material.lock"></i>
                                  <i class="mdi mdi-lock-open-variant text-success" v-else></i>
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul> 
                  </li>
                </ul>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
